import { createRouter, createWebHashHistory, RouteRecordRaw, useRoute } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/donantes",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/tablero",
        redirect: "/donantes",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/ruta/:year/:month/:day",
        name: "roadmap-explore",
        component: () => import("@/components/Roadmap.vue"),
      },
      {
        path: "/ruta",
        name: "roadmap",
        component: () => import("@/components/Roadmap.vue"),
      },
      {
        path: "/novedades",
        name: "news",
        component: () => import("@/components/NewsTable.vue"),
      },
      {
        path: "/motivos-no-conformidad",
        name: "news",
        component: () => import("@/components/RejectionReasonsTable.vue"),
      },
      {
        path: "/ruta/:id/capturar/:mode",
        name: "capture",
        component: () => import("@/components/CaptureSample.vue"),
      },
      {
        path: "/donantes",
        name: "donors",
        redirect: "/donantes/listado",
        component: () => import("@/views/Donors.vue"),
        children: [
          {
            path: "/donantes/listado",
            name: "donor-table",
            component: () => import("@/components/DonorsTable.vue"),
          },
          {
            path: "/donantes/mapa",
            name: "donor-map",
            component: () => import("@/components/DonorsMap.vue"),
          },
          {
            path: "/donantes/nuevo/:preloadDraftId?",
            name: "donor-new",
            component: () => import("@/components/DonorForm.vue"),
          },
          {
            path: "/donantes/:id",
            name: "donor-entity",
            component: () => import("@/components/DonorView.vue"),
          },
          {
            path: "/donantes/:id/editar",
            name: "donor-edit",
            component: () => import("@/components/DonorForm.vue"),
          },
          {
            path: "/donantes/:id/reactivar",
            name: "donor-reactivate",
            component: () => import("@/components/DonorForm.vue"),
          },
        ],
      },
      {
        path: "/solicitudes_pendientes",
        name: "solicitudes_pendientes",
        component: () => import("@/views/Workdays.vue"),
        children: [
          {
            path: "/solicitudes_pendientes/carga",
            name: "solicitudes-carga",
            component: () => import("@/components/DonorsTable.vue"),
          },
          {
            path: "/solicitudes_pendientes/precarga",
            name: "solicitudes-precarga",
            component: () => import("@/components/PreloadDraftsTable.vue"),
          },
          {
            path: "/solicitudes_pendientes/suspensiones",
            name: "solicitudes-suspensions",
            component: () => import("@/components/PendingSuspensionsTable.vue"),
          },
          {
            path: "/solicitudes_pendientes/bajas",
            name: "solicitudes-bajas",
            component: () => import("@/components/InactivationsTable.vue"),
          },
          {
            path: "/solicitudes_pendientes/reactivaciones",
            name: "solicitudes-reactivaciones",
            component: () => import("@/components/ReActivationsTable.vue"),
          },
        ]
      },
      {
        path: "/usuarios",
        name: "users",
        redirect: "/usuarios/listado",
        component: () => import("@/views/Users.vue"),
        children: [
          {
            path: "/usuarios/listado",
            name: "user-table",
            component: () => import("@/components/UsersTable.vue"),
          },

          {
            path: "/usuarios/nuevo",
            name: "user-new",
            component: () => import("@/components/UsersForm.vue"),
          },
          {
            path: "/usuarios/:id",
            name: "user-entity",
            redirect: { name: "user-edit" },
            component: () => import("@/components/UsersForm.vue"),
            children: [
              {
                path: "/usuarios/:id/editar",
                name: "user-edit",
                component: () => import("@/components/UsersForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/grupos",
        name: "grupos",
        redirect: "/grupos/listado",
        component: () => import("@/views/Groups.vue"),
        children: [
          {
            path: "/grupos/listado",
            name: "group-table",
            component: () => import("@/components/GroupsTable.vue"),
          },

          {
            path: "/grupos/nuevo",
            name: "group-new",
            component: () => import("@/components/GroupsForm.vue"),
          },
          {
            path: "/grupos/:id",
            name: "group-entity",
            redirect: { name: "group-edit" },
            component: () => import("@/components/GroupsForm.vue"),
            children: [
              {
                path: "/grupos/:id/editar",
                name: "group-edit",
                component: () => import("@/components/GroupsForm.vue"),
              },
            ],
          },
        ],
      },
      /////
      {
        path: "/reclamos",
        name: "reclamos",
        redirect: "/reclamos/listado",
        component: () => import("@/views/Claims.vue"),
        children: [
          {
            path: "/reclamos/listado",
            name: "claim-table",
            component: () => import("@/components/ClaimsTable.vue"),
          },

          {
            path: "/reclamos/nuevo",
            name: "claim-new",
            component: () => import("@/components/ClaimsForm.vue"),
          },
          {
            path: "/grupos/:id",
            name: "group-entity",
            redirect: { name: "group-edit" },
            component: () => import("@/components/GroupsForm.vue"),
            children: [
              {
                path: "/grupos/:id/editar",
                name: "group-edit",
                component: () => import("@/components/GroupsForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/bidones",
        name: "bidones",
        redirect: "/bidones/listado",
        component: () => import("@/views/Jerrycans.vue"),
        children: [
          {
            path: "/bidones/listado",
            name: "jerrycan-table",
            component: () => import("@/components/JerrycanTable.vue"),
          },

          {
            path: "/bidones/nuevo",
            name: "jerrycan-new",
            component: () => import("@/components/JerrycanForm.vue"),
          },
          {
            path: "/grupos/:id",
            name: "group-entity",
            redirect: { name: "group-edit" },
            component: () => import("@/components/GroupsForm.vue"),
            children: [
              {
                path: "/grupos/:id/editar",
                name: "group-edit",
                component: () => import("@/components/GroupsForm.vue"),
              },
            ],
          },
        ],
      },
      ////////

      {
        path: "/perfiles",
        name: "role-table",
        redirect: "/perfiles/lista",
        component: () => import("@/views/Roles.vue"),
        children: [
          {
            path: "/perfiles/lista",
            name: "role-list",
            component: () => import("@/components/RolesTable.vue"),
          },
          {
            path: "/perfil/:id",
            name: "role-edit",
            component: () => import("@/components/RolesForm.vue"),
          },
          {
            path: "/perfil/nuevo",
            name: "role-new",
            component: () => import("@/components/RolesForm.vue"),
          },
        ],
      },
      {
        path: "/auditoria",
        name: "audit",
        redirect: "/auditoria/explorar",
        component: () => import("@/views/Audits.vue"),
        children: [
          {
            path: "/auditoria/explorar",
            name: "audit-table",
            component: () => import("@/components/AuditsTable.vue"),
          },
        ],
      },
      {
        path: "/transportes",
        name: "transports",
        redirect: "/transportes/listado",
        component: () => import("@/views/Transports.vue"),
        children: [
          {
            path: "/transportes/listado",
            name: "transport-table",
            component: () => import("@/components/TransportsTable.vue"),
          },
          {
            path: "/transportes/nuevo",
            name: "transport-new",
            component: () => import("@/components/TransportsForm.vue"),
          },
          {
            path: "/transporte/:id",
            name: "transport-entity",
            redirect: { name: "transport-edit" },
            component: () => import("@/components/TransportsForm.vue"),
            children: [
              {
                path: "/transporte/:id/editar",
                name: "transport-edit",
                component: () => import("@/components/TransportsForm.vue"),
              },
            ],
          },
          {
            path: "/password-reset",
            name: "password-reset",
            component: () =>
              import(
                "@/views/crafted/authentication/basic-flow/PasswordReset.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
  },
  {
    path: "/login/change-expired-password",
    name: "change-expired-password",
    component: () => import("@/views/auth/ChangePassword.vue"),
    props: true,
  },
  {
    path: "/login/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    props: true,
    meta: { requiresAuth: false }
  },
  {
    path: "/login/reset-password",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
    props: true,
    meta: { requiresAuth: false }
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (to.matched.some(record => record.meta.requiresAuth) === false) {
    // Dispatch VERIFY_AUTH action if requires auth
    store.dispatch(Actions.VERIFY_AUTH);
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  next();
});

export default router;
